<template>
  <form id="frm_action">
  <div class="p-formgrid">
      <Panel>
        <template #header>
            Thông tin chung
        </template>
        <div class="p-card-field">
          <div class="p-col-8 p-offset-1">
            <div class="p-field p-grid">
              <label  class="p-col-12 p-mb-3 p-md-3 p-mb-md-0" style="text-align: left">Tên nhóm quyền (*)</label>
              <div class="p-col-12 p-md-9">
                <InputText required v-model="name"  style="width: 100%!important;" id="name" type="text" class="p-col-width" placeholder="Nhập" />
                <!--<InputText id="firstname" type="text" />-->
              </div>
            </div>
            <div class="p-field p-grid box-permission" v-for="(item_c, index_c) in list_controller" :key="index_c"   >
              <div class="p-col-6 box-left">
                <div class="box">{{ item_c.name }}</div>
              </div>
              <div class="p-col-6 box-right">
                <div class="box">
                  <div class="p-field-checkbox" style="margin-bottom: 0px" >
                    <!--<b-form-group >-->
                      <b-form-checkbox-group
                          v-model="item_c.checked_role"
                          :options="item_c.roles"
                          plain
                          stacked
                      ></b-form-checkbox-group>
                    <!--</b-form-group>-->
                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>
    </Panel>
    <Toolbar class="fixed-bottom-toolbar">
      <template slot="right">
        <Button
          :label="$constants.TEXT_BUTTON.BACK"
          @click="backToList()"
          class="p-button-warning"
        />
        <Button
          v-if="mode == 'edit'"
          :label="$constants.TEXT_BUTTON.EDIT"
          @click="saveData()"
          class="p-button-success"
        />
        <Button
          v-else
          :label="$constants.TEXT_BUTTON.ADD"
          @click="saveData()"
          class="p-button-success"
        />
      </template>
    </Toolbar>
  </div>
  </form>
</template>

<script>
import DataServices from '@/core/DataServices'
import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      name: '',
      phone: '',
      address: '',
      code: '',
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      categories: [],
      selectedCategory: null,
      selectedCategory_old : [],
      addCategory: [],
      delCategory: [],

      saving:false,
      addressData:null,
      placeResultData:null,
      /*addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}*/
      list_controller: [],

     selected: [], // Must be an array reference!
      /*options: [
       { text: 'Orange', value: 'orange' },
       { text: 'Apple', value: 'apple' },
       { text: 'Pineapple', value: 'pineapple' },
       { text: 'Grape', value: 'grape' }
     ]*/
    }
  },
  async mounted() {
    var where_controller = {active: {_eq: true},deleted: {_eq: false},parent_tab: {_eq: true}};
    var order_by_controller = {ordinal: 'desc'};
    var controller_list = await  this.$CoreService.getListData('controller',where_controller,null,null,order_by_controller);
    controller_list = this.$commonFuction.convertJsonObject(controller_list);

    //console.log("this.model:ssssssssssssssssssss",this.model);

    this.list_controller = controller_list;
    //var group_id = this.dataEdit.id;

    /*if (data){
      this.models = data;
    }*/
    var group_id = this.$route.params.id;
    console.log("sssssssssssssssssssssssssss");
    if (!this.$commonFuction.isEmpty(group_id)){

        console.log("group_idgroup_idgroup_id:",group_id)
        var where = {id: {'_eq': group_id}};
        var dataGroup = await this.$CoreService.getObjectData('group',where);
        console.log("datadatadatadata:",dataGroup)
        console.log("sssssssssssssssssssssssssssffffffffffffffff");
        console.log("sssssssssssssssssssssssssssffffffffffffffffddd:",dataGroup);
        this.name = dataGroup.name;
        var group_roles = (dataGroup.group_roles) ? dataGroup.group_roles:[];
        for (var m = 0; m < this.list_controller.length; m++) {
          var list_checked_roles = [];
          if (this.list_controller[m].roles) {
            var roles = this.list_controller[m].roles;
            for (var j = 0; j < roles.length; j++) {
              if (group_roles.map((el) => el.role_id).indexOf(roles[j].id) != -1) {
                list_checked_roles.push(roles[j].id);
              }
            }
          }
          this.list_controller[m].checked_role = list_checked_roles;
        }
    }


    //console.log(this.list_controller);

  },
  methods: {
    getAddressData: function (addressData, placeResultData, id) {
      this.addressData = addressData;
      this.placeResultData = placeResultData;
      console.log(JSON.stringify(addressData));
      console.log(JSON.stringify(placeResultData));
      console.log(id);
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }

    },
    convertPhone(numberPhone) {
      var phone = numberPhone
      if (numberPhone[0] === '0') {
        phone = phone.substring(1);
      }
      phone = phone.replace(' ', '')
      phone = '+84' + phone
      return phone
    },
    async getUserByPhoneNumber(phoneNumber) {
      // var phone = phoneNumber;
      // if (phoneNumber[0] === "0") {
      //   phone = phone.substring(1);
      // }
      // phone = phone.replace(' ', '');
      // phone = "+84" + phone;
      let query = `mutation MyMutation {
        checkPhoneNumber(phone: "${this.convertPhone(phoneNumber)}") {
          info
        }
      }`;
      await this.$apollo.mutate({
        mutation: gql(query)
      }).then(async(res) => {
        // console.log('=========> checkPhoneNumber: ', res)
        if (res) {
          this.firebaseID = res.data.checkPhoneNumber.info.uid
          await this.$apollo.query({
            query: DataServices.getList('users', {"fields" : "id"}),
            variables: {
              where_key: {
                phone: {'_eq': this.phone},
                deleted: {'_eq': false}
              }
            },
            fetchPolicy: "network-only"
          }).then((res) => {
            if (res && res.data.users.length > 0) this.userID = res.data.users[0].id
            else this.userID = null
            // console.log(this.userID)
          }).catch((error) => {
            console.log('ERROR: ', error.message)
          })
        }
      }).catch((error) => {
        console.log('ERROR: ', error.message)
        // CHƯA CÓ FIREBASE ID
        this.firebaseID = null
        this.userID = null
      })
    },
    async checkPhoneExist() {
      if (this.phone !== undefined && this.phone !== "") {
        // var phone = this.phone;
        // if (this.phone[0] === "0") {
        //   phone = phone.substring(1);
        // }
        // phone = phone.replace(' ', '');
        // phone = "+84" + phone;

        var where = {
          account: {'_eq': this.phone},
          type: {'_eq': 'PHONE'},
          deleted: {'_eq': false}
        }
        if (this.model !== undefined) where = Object.assign(where, {id: {'_neq': this.model.id}})
        var {data} = await this.$apollo.query({
          query: DataServices.getList('accounts', {"fields" : "id, account, type"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        })
        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      }
    },
    async saveData() {

     /* if(this.saving == true){
        return;
      }
      this.saving = true;*/
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
       // this.saving = false;
        this.$commonFuction.is_loading(false);
        return
      }

      if (!this.account_exist) {
        var params = {
          name: this.name,
          list_controller:this.list_controller
        }
        // console.log('saveData', params)
        this.$emit('save', params);
      }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if(files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF']
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Vui lòng upload file ảnh có định dạng sau: jpeg, jpg, gif, png");
            return;
          }
        }
        if (files.length + this.images.length > this.maxFiles) {
          alert("Hình ảnh quá số lượng tối đa là " + this.maxFiles + " hình");
        } else {
          var arrTemp = [], arrFiles = [];
          for (let file of files) {
            if (this.isImage(file) && file.size <= this.maxFileSize) {
              file.source = file;
              file.objectURL = window.URL.createObjectURL(file);
              arrTemp.push(file);
            }
            if (file.size > this.maxFileSize) arrFiles.push(file);
          }
          
          if (arrFiles.length > 0) {
            alert("Hình ảnh của bạn đã quá dung lượng 1MB");
          } else {
            if (this.images.length > 0 && this.images[0].file === '') {
              this.imagesDeletes.push(this.images[0])
            }
            this.images = [];
            this.images = this.images.concat.apply(this.images, arrTemp);
            // console.log(this.images)
            await this.validateData()
          }
        }
      }
    },
    onFileClear(index) {
      let img = this.images[index];
      this.images.splice(index, 1);
      if (img.file === '') this.imagesDeletes.push(img)
    }
  },
  /*apollo: {
    category: {
      query() {
        return DataServices.getList('category', {"fields" : "id name"});
      },
      update(data) {
        if(data.category){
          this.categories = data.category
        }
      },
      fetchPolicy: "network-only"
    },
  }*/
}
</script>

<style lang="scss">
  .p-multiselect {
    width: 450px;
  }
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
    
  }
  .category-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .2rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
  .p-multiselect-label-container{
    height: 40px;
  }
  </style>

<style >
.box-permission{
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 16px!important;
}
.box-permission .box-left{
  padding: 24px!important;
  border-right: 1px solid #E2E2E2;
  text-align: left;
}
.box-permission .box-right{
  padding: 24px!important;
}
</style>
